export const CHANNEL_GENERIC_PAGE_HEADER_FRAGMENT = `
  fragment channelGenericPageHeaderFragment on Channel {
    uid
    name
    description
    imageFileResource {
      uid
      path
    }
    _followerCount
    __typename
  }
`;
